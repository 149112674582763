import React from "react";
import { LoadingCard } from "../../components/card/loading";
import { MainContainer } from "../../containers/main-container";

interface Props {
  topCard?: React.ReactNode;
}

const HomeLoading: React.FC<Props> = ({ topCard }) => {
  return (
    <MainContainer>
      {topCard}
      {[...Array(5)].map((_, i) => (
        <LoadingCard key={`home-loading-card-${i}`} />
      ))}
    </MainContainer>
  );
};

export default HomeLoading;

import dynamic from "next/dynamic";
import React from "react";
import shallow from "zustand/shallow";
import SEO from "../components/seo";
import { getPageTitle } from "../libs/seo/page-title";
import HomeLoading from "../routes/home/loading";
import useAppStore from "../state/app-store";

const HomeContainer = dynamic(() => import("../routes/home/container"), {
  ssr: false,
  loading: () => <HomeLoading />,
});

const Home: React.FC = () => {
  const setAppBarTitle = useAppStore((state) => state.setAppBarTitle, shallow);
  setAppBarTitle("Reducera");

  return (
    <>
      <SEO title={getPageTitle("Spela reducerade system helt gratis", true)} />
      <HomeContainer />
    </>
  );
};

export default Home;
